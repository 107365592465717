.user {
    .container_content{

        &.verify_list{

            @include media-query(desktopL) {
                width: 100%;
                padding-left: 20px;
            }

            .text_results {
                color: $color-dark-blue2;
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 18px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }
            }

            .text_filters_applied{
                font-size: 14px;
                font-weight: 600;
                color: $color-gray;
                display: inline-block;
                margin: 0px;
                margin-bottom: 7px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }
            }


            .list_filter_containers{
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 10px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }

                .clean_filter_mobile{
                    display: inline-block;
                    margin: 0px;
                    font-size: 13px;
                    //Color de link
                    color: #0076c2;
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                        color: $color-bright-blue;
                        text-decoration: underline;
                    }

                    /* @include media-query(desktopL) {
                        display: none;
                    } */
                }
            }

            .cards_container{
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                justify-content: center;

                @include media-query(desktopL) {
                    justify-content: flex-start;
                }


                .card_booking{
                    width: 300px;
                    background-color: $color-white;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;

                    &.installer_uninstalled,
                    &.verified_design {
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            height: 100%;
                            background-color: #000000ac;
                            border-radius: 2px;
                        }
                    }

                     

                    .title_card{
                        font-size: 15px;
                        font-weight: 600;
                        display: flex;
                        padding: 10px;
                        padding-left: 20px;
                        text-transform: capitalize;
                        background-color: #fff;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;

                        .title_text {
                            margin-bottom: 0;
                            color: $color-dark-blue2;
                        }

                        .light {
                            min-width: 15px;
                            min-height: 15px;
                            background-color: transparent;
                            border-radius: 50%;
                            background-color: #ff0000;

                            &.installer_light{
                                background-color: #ff0000;
                            }

                            &.verified  {
                                background-color: #ff0000;
                            }

                            &.partial {
                                background-color: #ffdd00;
                            }

                            &.installer_installed {
                                background-color: #ffdd00;
                            }

                            &.installer_diurnal {
                                background-color: #00e708;
                            }

                            &.installer_uninstalled {
                                background-color: transparent;
                            }

                            &.verified_design {
                                background-color: transparent;
                            }


                        }

                    }

                    .card_item{
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                        color: $color-gray;
                        padding-left: 20px;
                        padding-right: 20px;

                        //cada impar
                        &:nth-child(even){
                            background-color: $color-light-blue;
                        }

                        //Segundo p
                        p:nth-child(2){
                            text-align: right;
                        }
                    }

                    .card_button{
                        border-bottom: 1px solid $color-light-gray;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-weight: 600;
                        z-index: 2;
                    }
                }
            }

            .filters_container{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .filter{
                    /* min-width: 120px; */
                    height: 30px;
                    background-color: #d9daea;
                    margin: 2px;

                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    color: $color-dark-blue2;
                    font-weight: $semibold;
                }

                .filter:last-child {
                    margin-right: auto; /* Esto centra el último elemento a la izquierda */
                }
            }


            .container_content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                padding-top: 20px;

                @include media-query(desktopL) {
                    
                    justify-content: flex-start;
                    padding-top: 0px;
                }

                .card_container {
                    background-color: $color-white;
                    width: 250px;
                    height: 620px;
                    min-width: 250px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    margin-right: 10px;
                    margin-bottom: 10px;

                    &.ddoh{
                        height: 550px;
                    }

                    .image_background {
                        height: 182px;
                        width: 100%;
                        overflow: hidden;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .status_container{
                                min-width: 80px;
                                height: 20px;
                                background-color: $color-dark-blue;
                                position: absolute;
                                bottom: 10px;
                                left: 10px; 
                                display: flex;
                                align-items: center;

                                .circle_status{
                                    width: 10px;
                                    height: 10px;
                                    background-color: $color-green;
                                    border-radius: 50%;
                                    margin-left: 5px;
                                }
                                p {
                                    color: $color-white;
                                    margin: 0px;
                                    margin-left: 4px;
                                    font-size: 11px;
                                }
                        }
                    }

                    .card_description {
                        .card_title {
                            font-size: 16px;
                            font-weight: 600;
                            color: $color-dark-blue2;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            margin-left: 15px;
                            text-transform: capitalize;
                        }

                        .row {
                            // Establece el padding-left para todos los elementos .col-6
                            .col-6 {
                                padding-left: 30px; // Ajusta el valor de padding según tus necesidades
                            }

                            // Anula el padding-left solo en los elementos .col-6 pares
                            .col-6:nth-child(even) {
                                padding-left: 0;
                            }

                            div {
                                margin-bottom: 10px;
                            }
                        }

                        .item_desc,
                        .title_desc {
                            margin: 0px;
                        }

                        .title_desc {
                            font-size: 10px;
                            font-weight: 600;
                            color: $color-dark-blue2;
                        }

                        .item_desc {
                            font-size: 12px;
                            font-weight: 400;
                            color: $color-gray;
                        }
                    }

                    .card_button_container {
                        border-top: 1px solid $color-light-gray;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .card_button {
                            border: none;
                            color: $color-bright-blue;
                            font-weight: 700;
                            background-color: transparent;
                            margin-top: 20px;
                            margin-bottom: 20px;
                            font-size: 14px;
                        }
                    }
                }
            }

            &.details{
                display: flex;
                flex-direction: column;
                padding-left: 0px;
                max-width: 1200px;


                
                .form_artes{
                    display: flex;
                    flex-direction: column;
                    margin-top: 21px;

                    label {
                        font-size: 14px;
                        font-weight: $bold;
                        color: #666;
                        margin-bottom: 10px;
                    }

                    textarea {
                        width: 100%;
                        height: 100px;
                        padding: 10px;
                        border: 1px solid $color-gray;
                        border-radius: 5px;
                        resize: none;
                        margin-bottom: 10px;
                    }
                }


                .form_container{
                    width: 100%;
                    max-width: 1200px;

                    .subtitle{
                        padding-left: 30px;
                        padding-top: 15px;
                    }

                    padding: 0px;

                    .items_container {
                        padding-left: 30px;
                        padding-right: 30px;
                        margin-bottom: 20px;
                        display: flex;
                        flex-wrap: wrap;
                      
                        .item {
                            min-width: 190px;
                            margin-bottom: 15px;
                            margin-right: 15px;
                            max-width: 190px;

                            &.comment {
                                width: 100%;
                                max-width: 100%;
                            }


                          p {
                            margin: 0;
                          }
                      
                          p:nth-child(1) {
                            font-size: 10px;
                            font-weight: $bold;
                            color: $color-dark-blue2;
                          }
                      
                          p:nth-child(2) {
                            font-size: 13px;
                            font-weight: $regular;
                            color: $color-gray;
                          }
                        }
                      }
                }

                .evidences_container{
                    form{
                        #id_url_installed, #id_url_uninstalled{
                            width: 100%;
                            height: 40px;
                            margin-bottom: 10px;

                            @include media-query(tablet) {
                                width: 50%;
                            }

                            
                        }
                    }
                }


                .table_container{
                    width: 100%;

                    th, td{
                        padding: 10px;
                        min-width: 200px;
                        text-align: center;
                        font-size: 14px;
                    }


                    tbody {
                        td {
                            input[type="checkbox"] {
                                width: 18px;
                                height: 18px;

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            a {
                                &:hover {
                                    cursor: pointer;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .button_filled{
                padding: 0px !important;
            }
        }

        
    }
}




















