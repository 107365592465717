.booking_list {
    .booking_list_container {
        display: flex;
        flex-direction: column;

        @include media-query(desktopL) {
            flex-direction: row;
        }

        .booking_list_container_filter {
            padding: 5px;

            @include media-query(tablet) {
                padding: 0px;
            }
            @include media-query(desktopL) {
                width: 35%;
                max-width: 300px;
                min-width: 300px;
            }

            .filter_sup {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                a {
                    width: 100%;
                }

                @include media-query(desktop) {
                    position: absolute;
                    top: 87px;
                    right: 30px;
                }

                .recordatorios,
                .gestionar_camp {
                    width: 48%;
                    height: 45px;
                    background-color: $color-white;
                    display: flex;
                    color: $color-dark-blue2;
                    font-weight: $semibold;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: $color-light-gray;
                        border: 1px solid $color-dark-blue2;
                    }

                    @include media-query(desktop) {
                        width: 200px;
                        padding-left: 15px;
                    }

                    p {
                        margin: 0px;
                        width: 133px;

                        // mediaquerie hasta 768px
                        @media (max-width: 450px) {
                            font-size: 10px;
                        }
                    }
                }

                .recordatorios {
                    @include media-query(desktop) {
                        margin-right: 10px;
                    }
                }
            }

            .container_filter {
                background-color: $color-white;
                padding: 10px;
                height: 100%;

                #accordionParent {
                    .accordion-item {
                        border: 0;

                        .accordion-header_parent {
                            margin-top: 0px;

                            .accordion-button_parent {
                                padding-top: 10px;
                                padding-bottom: 10px;
                                padding-left: 10px;
                                padding-right: 10px;

                                // mediaquerie hasta 768px
                                @media (max-width: 767px) {
                                    height: 50px;
                                }

                                &:not(.collapsed) {
                                    /* Estilos para .accordion-button cuando no tiene la clase .collapsed */
                                    color: #212529;
                                    background-color: transparent;
                                }

                                .filter_title {
                                    display: flex;
                                    color: $color-dark-blue2;
                                    font-size: 16px;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    padding-right: 10px;
                                    padding-left: 10px;

                                    p {
                                        margin: 0px;
                                        margin-left: 10px;
                                        font-weight: $bold;
                                    }
                                }
                            }
                        }

                        .accordion-body_parent {
                            padding: 10px;

                            .accordion_child {
                                .accordion-item {
                                    border: 0;
                                    border-bottom: 1px solid $color-light-gray;

                                    .accordion-header {
                                        margin: 0px;

                                        .accordion-button {
                                            padding-top: 20px;
                                            padding-bottom: 10px;
                                            padding-left: 10px;
                                            padding-right: 10px;
                                            font-size: 14px;
                                            font-weight: $semibold;
                                            // .accordion-button:not(.collapsed)

                                            &:not(.collapsed) {
                                                /* Estilos para .accordion-button cuando no tiene la clase .collapsed */
                                                color: #212529;
                                                background-color: transparent;
                                            }
                                        }
                                    }

                                    .accordion-body {
                                        padding-left: 10px;
                                        padding-right: 10px;
                                        padding-bottom: 15px;
                                        padding-top: 15px;

                                        input,
                                        select {
                                            width: 100%;
                                            height: 35px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .button_outline,
                .button_filled {
                    padding: 0px;
                    height: 35px !important;
                    margin-top: 20px;
                    width: 100% !important;
                }

                .button_filled {
                    margin-top: 10px;
                    display: none !important;

                    @include media-query(desktopL) {
                        display: none !important;
                    }
                }
            }
        }

        .booking_list_container_content {
            @include media-query(desktop) {
                max-width: 610px; // width cards + gap
            }
            @include media-query(desktopL) {
                width: 100%;
                padding-left: 20px;
                max-width: 630px; // padding + width cards + gap
            }
            @include media-query(desktopXL) {
                max-width: 940px;
            }

            .text_results {
                color: $color-dark-blue2;
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 18px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }
            }

            .text_filters_applied {
                font-size: 14px;
                font-weight: 600;
                color: $color-gray;
                display: inline-block;
                margin: 0px;
                margin-bottom: 7px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }
            }

            .list_filter_containers {
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 10px;
                margin-left: 10px;

                @include media-query(tablet) {
                    margin-left: 0;
                }

                @include media-query(desktopL) {
                    margin-top: 0px;
                }

                .clean_filter_mobile {
                    display: inline-block;
                    margin: 0px;
                    font-size: 13px;
                    //Color de link
                    color: #0076c2;
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                        color: $color-bright-blue;
                        text-decoration: underline;
                    }

                    /* @include media-query(desktopL) {
                        display: none;
                    } */
                }
            }

            .cards_container {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                justify-content: center;

                @include media-query(desktopL) {
                    justify-content: flex-start;
                }

                .card_booking {
                    width: 300px;
                    background-color: $color-white;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;

                    &.installed,
                    &.uninstalled {
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            height: 100%;
                            background-color: #000000ac;
                            border-radius: 2px;
                        }
                    }

                    .title_card {
                        font-size: 15px;
                        font-weight: 600;
                        display: flex;
                        padding: 10px;
                        padding-left: 20px;
                        text-transform: capitalize;
                        background-color: #fff;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;

                        .title_text {
                            margin-bottom: 0;
                            color: $color-dark-blue2;
                        }

                        .light {
                            min-width: 15px;
                            min-height: 15px;
                            background-color: #ff0000;
                            border-radius: 50%;

                            &.closed {
                                background-color: #ff0000;
                            }

                            &.approved {
                                background-color: #ffdd00;
                            }

                            &.verified {
                                background-color: #00e708;
                            }

                            &.installed {
                                background-color: transparent;
                            }

                            &.uninstalled {
                                background-color: transparent;
                            }
                        }
                    }

                    .card_item {
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                        color: $color-gray;
                        padding-left: 20px;
                        padding-right: 20px;

                        //cada impar
                        &:nth-child(even) {
                            background-color: $color-light-blue;
                        }

                        p:nth-child(2) {
                            text-align: end;
                        }
                    }

                    .card_button {
                        border-bottom: 1px solid $color-light-gray;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-weight: 600;
                        z-index: 2;
                    }
                }
            }

            .filters_container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .filter {
                    /* min-width: 120px; */
                    height: 30px;
                    background-color: #d9daea;
                    margin: 2px;

                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    color: $color-dark-blue2;
                    font-weight: $semibold;
                }

                .filter:last-child {
                    margin-right: auto; /* Esto centra el último elemento a la izquierda */
                }
            }

            .container_content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                padding-top: 20px;

                @include media-query(desktopL) {
                    justify-content: flex-start;
                    padding-top: 0px;
                }

                .card_container {
                    background-color: $color-white;
                    width: 250px;
                    height: 620px;
                    min-width: 250px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    margin-right: 10px;
                    margin-bottom: 10px;

                    &.ddoh {
                        height: 550px;
                    }

                    .image_background {
                        height: 182px;
                        width: 100%;
                        overflow: hidden;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .status_container {
                            min-width: 80px;
                            height: 20px;
                            background-color: $color-dark-blue;
                            position: absolute;
                            bottom: 10px;
                            left: 10px;
                            display: flex;
                            align-items: center;

                            .circle_status {
                                width: 10px;
                                height: 10px;
                                background-color: $color-green;
                                border-radius: 50%;
                                margin-left: 5px;
                            }
                            p {
                                color: $color-white;
                                margin: 0px;
                                margin-left: 4px;
                                font-size: 11px;
                            }
                        }
                    }

                    .card_description {
                        .card_title {
                            font-size: 16px;
                            font-weight: 600;
                            color: $color-dark-blue2;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            margin-left: 15px;
                            text-transform: capitalize;
                        }

                        .row {
                            // Establece el padding-left para todos los elementos .col-6
                            .col-6 {
                                padding-left: 30px; // Ajusta el valor de padding según tus necesidades
                            }

                            // Anula el padding-left solo en los elementos .col-6 pares
                            .col-6:nth-child(even) {
                                padding-left: 0;
                            }

                            div {
                                margin-bottom: 10px;
                            }
                        }

                        .item_desc,
                        .title_desc {
                            margin: 0px;
                        }

                        .title_desc {
                            font-size: 10px;
                            font-weight: 600;
                            color: $color-dark-blue2;
                        }

                        .item_desc {
                            font-size: 12px;
                            font-weight: 400;
                            color: $color-gray;
                        }
                    }

                    .card_button_container {
                        border-top: 1px solid $color-light-gray;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .card_button {
                            border: none;
                            color: $color-bright-blue;
                            font-weight: 700;
                            background-color: transparent;
                            margin-top: 20px;
                            margin-bottom: 20px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        // .pagination {
        //     margin-right: 10%;
        // }
    }
}
